<template scoped>
  <div>
    <el-form-item
      :rules="rules.regex"
      label="Regex"
      prop="read.untilMatch.regex"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.read.untilMatch.regex" placeholder="">
         <el-switch
          slot="append"
          v-model="logic.read.untilMatch.regexValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    validator_regex: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the regex should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      rules: {
        regex: [{ validator: this.validator_regex, trigger: "blur" }]
      },
    };
  },
};
</script>    